import { __assign } from "tslib";
import React, { Fragment } from 'react';
import { useTable, useSortBy } from 'react-table';
import { FaCheck, FaFilePdf, FaTriangleExclamation, FaRegEye, FaDownload } from 'react-icons/fa6';
var DocumentsList = function (_a) {
    var _b = _a.tableData, tableData = _b === void 0 ? [] : _b, _c = _a.hiddenColumns, hiddenColumns = _c === void 0 ? [] : _c, onRowClick = _a.onRowClick, handlePreview = _a.handlePreview, handleDownload = _a.handleDownload, _d = _a.className, className = _d === void 0 ? '' : _d;
    var getFormattedDate = function (date) {
        return new Date(date).toLocaleDateString();
    };
    var data = React.useMemo(function () { return tableData; }, []);
    var columns = React.useMemo(function () { return [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: function (props) { return (React.createElement("div", { className: 'tw-flex tw-items-center' },
                React.createElement(FaFilePdf, { size: 20, color: '#DC2626' }),
                React.createElement("span", { className: 'tw-align-middle tw-font-bold tw-ml-2' }, props.cell.value))); },
        },
        {
            Header: 'Status',
            accessor: 'signerSignatureDate',
            Cell: function (props) {
                return props.cell.value ? (React.createElement(FaCheck, { size: 20, color: '#15803D' })) : (React.createElement(FaTriangleExclamation, { size: 20, color: '#FF8D02' }));
            },
        },
        {
            Header: 'Todo',
            accessor: 'todo',
            Cell: function (props) {
                return props.row.original.signerSignatureDate && props.cell.value ? (React.createElement("p", null, props.cell.value)) : ('--');
            },
        },
        {
            Header: 'Date',
            accessor: 'createdAt',
            Cell: function (props) {
                return props.row.original.signerSignatureDate && props.cell.value ? (React.createElement("p", null,
                    React.createElement("b", null, getFormattedDate(props.cell.value)))) : ('-/-/-');
            },
        },
        {
            Header: 'View',
            accessor: 'view',
            disableSortBy: true,
            Cell: function (props) { return (React.createElement(FaRegEye, { size: 20, className: handlePreview ? 'cursor-pointer' : '', color: handlePreview ? '#1F2937' : '#9CA3AF', onClick: function (event) {
                    event.stopPropagation();
                    if (handlePreview) {
                        handlePreview(props.row);
                    }
                } })); },
        },
        {
            Header: 'Download',
            accessor: 'download',
            disableSortBy: true,
            Cell: function (props) { return (React.createElement(FaDownload, { size: 20, className: handleDownload ? 'cursor-pointer' : '', color: handleDownload ? '#1F2937' : '#9CA3AF', onClick: function (event) {
                    event.stopPropagation();
                    if (handleDownload) {
                        handleDownload(props.row);
                    }
                } })); },
        },
    ]; }, []);
    var table = useTable({ columns: columns, data: data, initialState: { hiddenColumns: hiddenColumns } }, useSortBy);
    var getTableProps = table.getTableProps, getTableBodyProps = table.getTableBodyProps, headerGroups = table.headerGroups, rows = table.rows, prepareRow = table.prepareRow;
    return (React.createElement("div", { className: className },
        React.createElement("table", __assign({ className: 'tw-w-full tw-text-xs' }, getTableProps()),
            React.createElement("thead", { className: 'tw-mb-8' }, headerGroups.map(function (headerGroup, headerIndex) { return (React.createElement("tr", __assign({ key: "table-head-row-".concat(headerIndex) }, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(function (column, index) { return (React.createElement("th", __assign({ key: "table-head-".concat(index), className: 'tw-text-left tw-p-2 md:tw-p-4', style: { verticalAlign: 'center' } }, column.getHeaderProps(column.getSortByToggleProps())),
                column.render('Header'),
                column.canSort && (React.createElement("span", { className: 'tw-ml-1' }, column.isSorted ? (React.createElement(Fragment, null, column.isSortedDesc ? (React.createElement("i", { className: 'fas fa-sort-down' })) : (React.createElement("i", { className: 'fas fa-sort-up' })))) : (React.createElement("i", { className: 'fas fa-sort', style: { opacity: '.25' } })))))); }))); })),
            React.createElement("tbody", __assign({}, getTableBodyProps()), rows.map(function (row, index) {
                prepareRow(row);
                return (React.createElement("tr", __assign({ key: "table-row-".concat(index) }, row.getRowProps(), { className: 'box-shadow tw-border tw-rounded-md' }), row.cells.map(function (cell) {
                    return (React.createElement("td", __assign({ key: "table-data-".concat(index) }, cell.getCellProps(), { className: 'tw-p-2 md:tw-p-4 tw-mt-2', onClick: function (event) {
                            event.preventDefault();
                            onRowClick === null || onRowClick === void 0 ? void 0 : onRowClick(row.values);
                        } }), cell.render('Cell')));
                })));
            })))));
};
export default DocumentsList;
