import { __assign, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import { Counter, Captions, Fullscreen, Slideshow, Thumbnails, Zoom, Share, Download, Inline, } from 'yet-another-react-lightbox/plugins';
// CSS yet-another-react-lightbox
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
var PLUGIN_SWITCH_COMP = {
    counter: Counter,
    captions: Captions,
    fullScreen: Fullscreen,
    zoom: Zoom,
    slideshow: Slideshow,
    thumbnails: Thumbnails,
    share: Share,
    download: Download,
    inline: Inline,
};
var ImageLightBox = function (_a) {
    var _b, _c;
    var _d = _a.showGallery, showGallery = _d === void 0 ? false : _d, _e = _a.pluginSwitch, pluginSwitch = _e === void 0 ? {
        counter: false,
        captions: false,
        fullScreen: false,
        zoom: false,
        slideshow: false,
        thumbnails: false,
        share: false,
        download: false,
        inline: false,
    } : _e, _f = _a.open, open = _f === void 0 ? false : _f, props = __rest(_a, ["showGallery", "pluginSwitch", "open"]);
    var slideShow = pluginSwitch.slideshow ? { autoPlay: false, delay: 3000 } : {};
    var inline = pluginSwitch.inline
        ? {
            style: {
                width: '100%',
                maxWidth: '900px',
                aspectRatio: '3 / 2',
                margin: '0 auto',
            },
        }
        : {};
    var _g = useState(false), showLightBox = _g[0], setShowLightBox = _g[1];
    var _h = useState(-1), currentImageIndex = _h[0], setCurrentImageIndex = _h[1];
    var pluginArray = (function () {
        var newPluginArray = [];
        for (var plugin in pluginSwitch) {
            if (pluginSwitch[plugin] === true) {
                newPluginArray.push(PLUGIN_SWITCH_COMP[plugin]);
            }
        }
        return newPluginArray;
    })();
    useEffect(function () {
        setShowLightBox(open);
    }, [open]);
    return (React.createElement("div", null,
        showGallery && (React.createElement("div", { className: 'tw-grid tw-grid-cols-1 sm:tw-grid-cols-4 md:tw-grid-cols-6 tw-gap-3' }, ((_b = props.slides) === null || _b === void 0 ? void 0 : _b.length) &&
            ((_c = props.slides) === null || _c === void 0 ? void 0 : _c.map(function (imageItems, index) {
                return (React.createElement("div", { className: 'tw-relative tw-cursor-pointer', key: 'gallery-image-' + index },
                    React.createElement("img", { src: imageItems.src, className: 'tw-object-cover tw-rounded tw-border tw-p-1', loading: 'lazy', onClick: function () {
                            setCurrentImageIndex(index);
                            setShowLightBox(true);
                        } })));
            })))),
        showLightBox && (React.createElement(Lightbox, __assign({ open: showLightBox, close: function () { var _a; return (props.close ? (_a = props.close) === null || _a === void 0 ? void 0 : _a.call(props) : setShowLightBox(false)); }, slideshow: slideShow, inline: inline, slides: props.slides, plugins: pluginArray, index: currentImageIndex < 0 ? 0 : currentImageIndex }, props)))));
};
export default ImageLightBox;
