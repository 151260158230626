function debounceEvent(callbackFunc, delay) {
    var timer;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (timer)
            clearTimeout(timer);
        timer = setTimeout(function () {
            timer = null;
            callbackFunc.apply('', args);
        }, delay);
    };
}
export default debounceEvent;
