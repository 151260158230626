export var ICON_SEARCH = 'fas fa-search';
export var ICON_COURSE = 'fas fa-layer-group';
export var ICON_PLUS = 'fas fa-plus';
export var ICON_PLUS_CIRCLE = 'fas fa-plus-circle';
export var ICON_RESOURCE = 'fas fa-photo-video';
export var ICON_TAGS = 'fas fa-tags';
export var ICON_COHORT = 'fas fa-users';
export var ICON_TOPIC = 'far fa-sticky-note';
export var ICON_MATERIAL = 'far fa-file-alt';
export var ICON_CHALLENGE = 'fas fa-clipboard-list';
export var ICON_QUIZ = 'fas fa-list-ol';
export var ICON_ROLE = 'fas fa-shield-alt';
export var ICON_SEND = 'fas fa-paper-plane';
export var ICON_COPY = 'fas fa-copy'; // 'far fa-clipboard'
export var ICON_EXPERT = 'fas fa-user-ninja';
export var ICON_CREDIT_CARD = 'far fa-credit-card';
export var ICON_GROUP = 'far fa-object-group';
export var ICON_LIST = 'far fa-list-alt';
export var ICON_USER = 'fas fa-user';
export var ICON_USERS = 'fas fa-users';
export var ICON_USER_CIRCLE = 'far fa-user-circle';
export var ICON_USER_INFO = 'far fa-address-card';
export var ICON_USER_EDIT = 'fas fa-user-edit';
export var ICON_DASHBOARD = 'fas fa-th-large';
export var ICON_TURBINE = 'fas fa-fan';
export var ICON_AWARD = 'fas fa-award';
export var ICON_BELL = 'fas fa-bell';
export var ICON_EXTERNAL_LINK = 'fas fa-external-link-alt';
export var ICON_PENCIL = 'fas fa-pencil-alt';
export var ICON_PENCIL_BOX = 'fas fa-edit';
export var ICON_MAP_PIN = 'fas fa-map-marker-alt';
export var ICON_MAP_LOCATION = 'fas fa-map-marked-alt';
export var ICON_SETTINGS = 'fas fa-cog';
export var ICON_SKILL = 'fas fa-atom';
export var ICON_REFRESH = 'fas fa-sync-alt';
export var ICON_CHART = 'fas fa-chart-line';
export var ICON_PATHWAY = 'fas fa-wave-square';
export var ICON_ATTENDANCE = 'fas fa-user-check';
export var ICON_BRANDING = 'fas fa-image';
export var ICON_LOCK = 'fas fa-lock';
export var ICON_USER_LOCK = 'fas fa-user-lock';
export var ICON_SHARE = 'fas fa-share-alt';
export var ICON_FOLDER = 'fas fa-folder';
export var ICON_FOLDER_ADD = 'fas fa-folder-plus';
export var ICON_FOLDER_OPEN = 'fas fa-folder-open';
export var ICON_TRASH = 'far fa-trash-alt';
export var ICON_MINUS = 'fas fa-minus';
export var ICON_MINUS_CIRCLE = 'fas fa-minus-circle';
export var ICON_TRIANGLE_BANG = 'fas fa-exclamation-triangle';
export var ICON_CHECK = 'fas fa-check';
export var ICON_CHECK_CIRCLE = 'fas fa-check-circle';
export var ICON_CIRCLE_ARROW_LEFT = 'fas fa-arrow-circle-left';
export var ICON_ARROW_LEFT = 'fas fa-arrow-left';
export var ICON_ARROW_RIGHT = 'fas fa-arrow-right';
export var ICON_UPLOAD = 'fas fa-cloud-upload-alt';
export var ICON_DOWNLOAD = 'fas fas fa-download';
export var ICON_DOWNLOAD_CLOUD = 'fas fa-cloud-download-alt';
export var ICON_REORDER = 'fa fa-arrows';
export var ICON_EYE_SLASH = 'fas fa-eye-slash';
export var ICON_EYE = 'fas fa-eye';
export var ICON_BROADCAST_TOWER = 'fas fa-broadcast-tower';
export var ICON_TASKS = 'fas fa-tasks';
export var ICON_X = 'fas fa-times';
export var ICON_X_CIRCLE = 'fas fa-times-circle';
export var ICON_INFO_CIRCLE = 'fas fa-info-circle';
export var ICON_ELLIPSIS = 'fas fa-ellipsis-h';
export var ICON_CHEVRON_LEFT = 'fas fa-chevron-left';
export var ICON_CHEVRON_RIGHT = 'fas fa-chevron-right';
export var ICON_SUPPORT = 'fa fa-life-ring';
export var ICON_PRINT = 'fas fa-print';
export var ICON_MAIL = 'fas fa-envelope';
export var ICON_PROJECTS = 'fas fa-images';
export var ICON_RECORDS = 'far fa-list-alt';
// CLOCKS
export var ICON_CLOCK = 'fa fa-clock';
export var ICON_CLOCK_O = 'fa fa-clock-o';
// CARET
export var ICON_CARET_RIGHT = 'fas fa-caret-right';
export var ICON_CARET_DOWN = 'fas fa-caret-down';
// CARET DIRECTIONAL
export var ICON_CARET_SQUARE_UP = 'fa fa-caret-square-o-up';
export var ICON_CARET_SQUARE_DOWN = 'fa fa-caret-square-o-down';
export var ICON_CARET_SQUARE_LEFT = 'fa fa-caret-square-o-left';
export var ICON_CARET_SQUARE_RIGHT = 'fa fa-caret-square-o-right';
// SORT
export var ICON_SORT = 'fas fa-sort';
export var ICON_SORT_DOWN = 'fas fa-sort-down';
export var ICON_SORT_UP = 'fas fa-sort-up';
// WORKFORCE
export var ICON_ROCKET = 'fas fa-rocket';
export var ICON_BRIEFCASE = 'fas fa-briefcase';
export var ICON_TRAININGS = 'fa fa-sort-amount-desc';
export var ICON_GRADUATION_CAP = 'fas fa-graduation-cap'; // apprenticeships
// export const ICON_USER_GRADUATE = 'fas fa-user-graduate'; // apprenticeship user
export var ICON_CITY = 'fas fa-city'; // employers
export var ICON_INVOICE_DOLLAR = 'fas fa-file-invoice-dollar'; // wage schedule
// RESOURCES
export var ICON_LINK = 'fas fa-link';
export var ICON_FILE = 'far fa-file';
export var ICON_FILE_INVOICE = 'fas fa-file-invoice';
export var ICON_FILE_IMAGE = 'far fa-file-image';
export var ICON_FILE_PDF = 'far fa-file-pdf';
export var ICON_FILE_EXCEL = 'far fa-file-excel';
// WEATHER
export var ICON_CLOUD = 'fas fa-cloud';
// CHECKBOXES
export var ICON_CHECKBOX_ALL = 'fa fa-check-square';
export var ICON_CHECKBOX_SOME = 'fa fa-minus-square';
export var ICON_CHECKBOX_EMPTY = 'fa fa-square-o';
// EMOJIS
export var ICON_THUMBS_UP = 'fa fa-thumbs-up';
export var ICON_THUMBS_UP_EMPTY = 'fa fa-thumbs-o-up';
export var ICON_THUMBS_DOWN = 'fa fa-thumbs-down';
export var ICON_THUMBS_DOWN_EMPTY = 'fa fa-thumbs-o-down';
export var ICON_MICROPHONE = 'fa-solid fa-microphone';
