import React, { useState, useEffect } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import './ListCard.css';
var ListCard = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.title, title = _c === void 0 ? '' : _c, _d = _a.subtitle, subtitle = _d === void 0 ? '' : _d, _e = _a.subtitleMaxChars, subtitleMaxChars = _e === void 0 ? 45 : _e, _f = _a.primaryLinkText, primaryLinkText = _f === void 0 ? '' : _f, primaryOnClick = _a.primaryOnClick, _g = _a.tagsList, tagsList = _g === void 0 ? [] : _g, durationCurrent = _a.durationCurrent, durationTotal = _a.durationTotal, _h = _a.durationText, durationText = _h === void 0 ? '' : _h, _j = _a.rightCornerText, rightCornerText = _j === void 0 ? '' : _j;
    var _k = useState(0), hiddenCount = _k[0], setHiddenCount = _k[1];
    useEffect(function () {
        window.addEventListener('resize', handleResize);
        handleResize();
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    var handleResize = function () {
        var parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
            var temp_1 = 0;
            var newHiddenCount_1 = 0;
            tagsList.map(function (tag, index) {
                var currentTag = document.getElementById('tag' + index);
                if (currentTag) {
                    if (temp_1 + currentTag.clientWidth + 200 < parentDiv.clientWidth) {
                        temp_1 = temp_1 + currentTag.clientWidth;
                    }
                    else {
                        newHiddenCount_1 += 1;
                    }
                }
            });
            setHiddenCount(newHiddenCount_1);
        }
    };
    var showSubTitle = function (value) {
        return value.length > subtitleMaxChars ? value.slice(0, subtitleMaxChars) + '...' : value;
    };
    var getPercentage = function () {
        var current = durationCurrent !== null && durationCurrent !== void 0 ? durationCurrent : 0;
        var total = durationTotal !== null && durationTotal !== void 0 ? durationTotal : 0;
        return Math.round((current / total) * 100);
    };
    return (React.createElement("div", { id: 'parentDiv', className: "box-shadow tw-flex tw-justify-between tw-flex-col tw-rounded-md tw-p-5 tw-border tw-relative tw-transition tw-ease-in-out tw-delay-75 tw-duration-500 hover:tw-translate-y-[-10px] ".concat(!primaryLinkText ? 'tw-cursor-pointer' : '', " ").concat(className), onClick: function () {
            if (!primaryLinkText) {
                primaryOnClick === null || primaryOnClick === void 0 ? void 0 : primaryOnClick();
            }
        } },
        React.createElement("div", { className: 'tw-flex tw-flex-row tw-text-md' },
            durationCurrent && durationTotal && (React.createElement("div", { className: 'pie-chart', style: {
                    background: "\n          radial-gradient(\n            circle closest-side,\n            white 0,\n            white 0%,\n            transparent 10%,\n            transparent 100%,\n            white 0\n          ),\n          conic-gradient(\n            #a1a1a1 0,\n            #a1a1a1 ".concat(getPercentage(), "%,\n            #8E44AD 0,\n            #8E44AD 100%\n        )"),
                } })),
            React.createElement("div", { className: 'tw-grow tw-h-12' }, durationText),
            rightCornerText && React.createElement("div", { className: 'tw-text-[#8E44AD]' }, rightCornerText)),
        React.createElement("div", { className: 'tw-text-2xl tw-text-black tw-mb-3' }, title),
        React.createElement("p", { className: 'tw-text-lg tw-mb-4 tw-text-[#404040]' }, showSubTitle(subtitle)),
        tagsList.length > 0 && (React.createElement("div", { className: 'tw-flex tw-mb-4', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }, tagsList === null || tagsList === void 0 ? void 0 :
            tagsList.map(function (tag, index) {
                return tagsList.length - hiddenCount > index && (React.createElement("div", { id: 'tag' + index, key: index, className: 'tw-bg-[#F8F9FA] tw-font-bold tw-rounded-full tw-px-4 tw-py-2 tw-mr-2 tw-text-sm' }, tag));
            }),
            React.createElement("div", null, hiddenCount > 0 && (React.createElement("div", { className: 'tw-bg-[#F8F9FA] tw-font-bold tw-rounded-full tw-px-4 tw-py-2 tw-mr-2' },
                "+",
                hiddenCount))))),
        primaryLinkText && (React.createElement("div", { className: 'tw-flex tw-justify-end tw-mt-5 tw-text-[#8E44AD] ' },
            React.createElement("button", { className: 'tw-justify-end tw-text-2xl tw-flex', type: 'button', onClick: function (event) {
                    event === null || event === void 0 ? void 0 : event.stopPropagation();
                    if (primaryLinkText) {
                        primaryOnClick === null || primaryOnClick === void 0 ? void 0 : primaryOnClick();
                    }
                } },
                primaryLinkText,
                React.createElement(HiArrowRight, { size: 28, className: 'tw-ml-2 tw-mt-1' }))))));
};
export default ListCard;
