import { __assign, __rest } from "tslib";
import React from 'react';
var CircleButton = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.size, size = _c === void 0 ? 'md' : _c, onClick = _a.onClick, props = __rest(_a, ["className", "size", "onClick"]);
    var SIZE = {
        sm: '3',
        md: '4',
        lg: '5',
    };
    return (React.createElement("button", __assign({ className: "padding-".concat(SIZE[size], " all-side-box-shadow-").concat(SIZE[size], " tw-rounded-full ").concat(className), type: 'button', onClick: onClick }, props), props.children));
};
export default CircleButton;
