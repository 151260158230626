import React from 'react';
import { FaRegClock } from 'react-icons/fa6';
var TimeBadge = function (_a) {
    var duration = _a.duration, className = _a.className, _b = _a.variant, variant = _b === void 0 ? 'filled' : _b, _c = _a.iconColor, iconColor = _c === void 0 ? '#FFFFFF' : _c, _d = _a.textColor, textColor = _d === void 0 ? '#FFFFFF' : _d, _e = _a.backgroundColor, backgroundColor = _e === void 0 ? '#00FFFF' : _e;
    var textStyle = {
        color: textColor,
    };
    var bgStyle = {
        backgroundColor: "".concat(variant === 'filled' ? backgroundColor : variant === 'outlined' ? "".concat(backgroundColor, "22") : ''),
        borderColor: "".concat(variant === 'outlined' ? backgroundColor : ''),
    };
    var TIMEBADGE_VARIANT = {
        filled: '',
        outlined: 'tw-border tw-border-solid tw-border-black tw-border-1',
        disabled: 'tw-bg-neutral-300',
    };
    return (React.createElement("div", { className: "tw-ml-2 tw-h6 tw-m-0 ".concat(className) },
        React.createElement("div", { className: [
                'tw-inline-flex tw-items-center tw-badge tw-rounded tw-px-2',
                TIMEBADGE_VARIANT[variant],
            ].join(' '), style: bgStyle },
            React.createElement(FaRegClock, { color: iconColor, className: "tw-mr-1" }),
            React.createElement("span", { style: textStyle }, duration))));
};
export default TimeBadge;
