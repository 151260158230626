import { __rest } from "tslib";
import React from 'react';
var Icon = function (_a) {
    var props = __rest(_a, []);
    var _b = props.className, className = _b === void 0 ? '' : _b;
    var style = {
        fontSize: props.size ? "".concat(props.size, "px") : '',
        color: props.color ? props.color : ''
    };
    return (React.createElement("i", { className: className, style: style, "aria-hidden": "true" }));
};
export default Icon;
