import { __assign, __rest } from "tslib";
import React from 'react';
var Button = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'button' : _b, _c = _a.variant, variant = _c === void 0 ? 'primary' : _c, _d = _a.size, size = _d === void 0 ? 'md' : _d, _e = _a.className, className = _e === void 0 ? '' : _e, props = __rest(_a, ["type", "variant", "size", "className"]);
    var BUTTON_VARIANT = {
        primary: 'tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white',
        secondary: 'tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white',
        success: 'tw-bg-green-500 hover:tw-bg-green-700 tw-text-white',
        danger: 'tw-bg-red-500 hover:tw-bg-red-700 tw-text-white',
        warning: 'tw-bg-yellow-500 hover:tw-bg-yellow-700 tw-text-white',
        info: 'tw-bg-blue-500 hover:tw-bg-blue-700 tw-text-white',
        light: 'tw-bg-gray-50 hover:tw-bg-gray-100 tw-text-gray-900',
        dark: 'tw-bg-gray-900 hover:tw-bg-gray-700 tw-text-white',
        white: 'tw-bg-white tw-border tw-border-[#d2d2d2] hover:tw-bg-gray-50 hover:tw-border-black tw-text-[#2c4a37]',
        link: 'hover:tw-underline',
    };
    var BUTTON_SIZES = {
        sm: 'tw-py-1 tw-px-2 tw-text-sm',
        md: 'tw-py-2 tw-px-4 tw-text-sm',
        lg: "tw-py-3 tw-px-6 tw-text-base ".concat(variant === 'white' ? 'tw-border-2' : ''),
    };
    return (React.createElement("button", __assign({ className: ['tw-rounded', BUTTON_VARIANT[variant], BUTTON_SIZES[size], className].join(' '), type: type }, props), props.children || 'No children provided'));
};
export default Button;
